


























































































































































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { BookMuluText } from "@/utils/base";
import { Getchecker, AddGuji, EditGuji } from "@/request/check";
import { SearchBook, GetBookZhangjie } from "@/request/mark";
import {
  GetBookCategory,
  ChangeBookCategoty,
  UpdateBookFiles,
  GetShitiList,
} from "@/request/storehouse";
import {
  UploadFile,
  GetShitOption,
  GetYuanwenList,
  GetPassBook,
} from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
import YuanwenHengji from "../components/hengji/yuanwen-hengji.vue";
import RenwuList from "../components/renwu-list.vue";
import Book from "@/components/book.vue";
@Component({
  components: {
    MainHeader,
    YuanwenHengji,
    RenwuList,
    Book,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private ifShowEditFenlei: any = false;
  private fenleiData: any = {};
  private fenleis: any = [];
  private data: any[] = [];
  private editData: any = {};
  private ifShowHengji: any = false;
  private hengjiData: any = [];
  private hengjiId: any = "";
  private types: any = ["古籍"];
  private status: any = ["未校验", "校验中", "已校验"];
  private jiaoyanyuan: any = [];
  private shumings: any = [];
  private zhangjies: any = [];
  private ifShowAddBook: any = false;
  private ifShowRenwu: any = false;
  private ifShowRead: any = false;
  private readData: any = {};
  private refreshTime: any = "";
  private addBookData: any = {};
  private book_id: any = "";
  private renwuTime: any = "";
  private fenlei: any = []; // 分类选项
  private ifShowSearch: any = false; // 高级搜索
  private searchData: any = {
    古籍名称: [],
  }; // 高级搜索数据
  private chaodai: any = []; // 高级搜索朝代选项
  private yijia: any = []; // 高级搜索医家选项
  private books: any = []; // 有已通过章节的书籍列表
  private getBookName(name: any) {
    // 判断收尾是否是书名号
    if (!name) {
      return name;
    }
    const index1 = name.indexOf("《");
    const index2 = name.indexOf("》");
    let str: any = "";
    if (index1 === 0 && index2 == name.length - 1) {
      str = name.substring(1, name.length - 1);
    } else {
      str = name;
    }
    return str;
  }
  private openSearch() {
    this.searchData = {
      古籍名称: [],
    };
    this.ifShowSearch = true;
  }
  private remoteBook(val: any) {
    const params: any = {
      params: {
        search: val || "",
      },
    };
    GetPassBook(this, params).then((res: any) => {
      this.books = res;
    });
  }
  private getChaodai() {
    const params: any = {
      params: {
        语义名称: "中国朝代",
      },
    };
    GetShitOption(this, params).then((res: any) => {
      this.chaodai = res;
    });
  }
  private getYijia() {
    const params: any = {
      params: {
        语义名称: "中医医家",
      },
    };
    GetShitOption(this, params).then((res: any) => {
      this.yijia = res;
    });
  }
  private goSearch() {
    if (!this.searchData["检索内容"]) {
      this.$message.warning("请输入检索内容");
      return;
    }
    this.$router.push({
      path: "/main/check/ywqf-search",
      query: {
        search: JSON.stringify(this.searchData),
      },
    });
  }
  private getFenlei() {
    const params: any = {
      params: {
        table_name: "古籍分类",
        page_num: 1000,
        status: "",
      },
    };
    GetShitiList(this, params).then((res: any) => {
      this.fenlei = res.data;
    });
  }
  private editBook(item: any) {
    this.addBookData = {
      name: this.getBookName(item["书名"]),
      category: item.categ_id,
      book_id: item.book_id,
      image_url: item.image_url,
    };
    this.getFenleis();
    this.ifShowAddBook = true;
  }
  private openRead(item: any) {
    this.readData = item;
    this.ifShowRead = true;
    this.refreshTime = new Date().getTime();
  }
  private goShuxing(item: any) {
    this.$store.commit("updateIfCompose", true);
    this.$router.push({
      path: "/main/check/bentijiaoyan/compose",
      query: {
        id: item.id,
        kind: item["语义类型"],
      },
    });
  }
  private goQiefen(item: any) {
    if (item["校验状态"] !== "已校验") {
      return;
    }
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/qiefen",
      query: {
        id: item.book_id,
        name: item["书名"].split("《")[1].split("》")[0],
      },
    });
  }
  private openRenwuList(item: any) {
    if (!item["任务数"] || item["任务数"] < 1) {
      return;
    }
    this.book_id = item.book_id;
    this.renwuTime = new Date().getTime();
    this.ifShowRenwu = true;
  }
  private addBook() {
    this.addBookData = {};
    this.getFenleis();
    this.ifShowAddBook = true;
  }
  private addBookSure() {
    if (!this.addBookData.name) {
      this.$message.warning("请输入古籍名称！");
      return;
    }
    if (!this.addBookData.category) {
      this.$message.warning("请选择古籍分类！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.addBookData.book_id) {
      const params: any = {
        book_id: this.addBookData.book_id,
        古籍名称: this.addBookData.name,
        古籍分类: this.addBookData.category,
        image_url: this.addBookData["image_url"],
      };
      EditGuji(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.warning("编辑成功");
          this.ifShowAddBook = false;
          this.search();
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const params: any = {
        古籍名称: this.addBookData.name,
        古籍分类: this.addBookData.category,
      };
      AddGuji(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.warning("新增古籍成功");
          this.ifShowAddBook = false;
          this.search();
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private cancelFenlei() {
    this.ifShowEditFenlei = false;
  }
  private getFenleis() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetBookCategory(this, loading)
      .then((res: any) => {
        loading.close();
        this.fenleis = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private goReadZiliao(item: any) {
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/ziliao",
      query: {
        id: item.book_id,
        name: item["书名"].split("《")[1].split("》")[0],
      },
    });
  }
  private goEdit(item: any) {
    this.$router.push({
      path: "/main/check/ywqf-edit",
      query: {
        id: item.book_id,
        name: item["书名"].split("《")[1].split("》")[0],
      },
    });
  }
  private openFenlei(item: any) {
    this.fenleiData = JSON.parse(JSON.stringify(item));
    this.fenleiData.category = item.categ_id;
    this.getFenleis();
    this.ifShowEditFenlei = true;
  }
  private fenleiSure() {
    if (!this.fenleiData["category"]) {
      this.$message.warning("请选择分类");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      categ_id: this.fenleiData["category"],
      book_id: this.fenleiData.book_id,
    };
    ChangeBookCategoty(this, params, loading)
      .then(() => {
        loading.close();
        this.ifShowEditFenlei = false;
        this.$message.success("选择分类成功");
        this.getList();
      })
      .catch(() => {
        loading.close();
      });
  }
  private upload(file: any, item: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UploadFile(this, form, loading)
      .then((res: any) => {
        loading.close();
        // this.$emit("addOne", res.full_file_url);
        const params: any = {
          book_id: item.book_id,
          file_urls: [res.full_file_url],
        };
        const loading2: any = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        UpdateBookFiles(this, params, loading2)
          .then((res: any) => {
            loading2.close();
            this.$message.success("上传成功");
            this.getList();
          })
          .catch(() => {
            loading2.close();
          });
      })
      .catch(() => {
        loading.close();
      });
  }
  private uploadBook(file: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UploadFile(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.addBookData["image_url"] = res.full_file_url;
      })
      .catch(() => {
        loading.close();
      });
  }
  private cancelHengji() {
    this.ifShowHengji = false;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private shumingFocus() {
    this.remoteShumingMethod("");
  }
  private remoteShumingMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    SearchBook(this, params).then((res: any) => {
      this.shumings = res;
    });
  }
  private selectBook(e: any, ifMounted?: any) {
    const params: any = {
      book_id: e._id,
    };
    GetBookZhangjie(this, params).then((res: any) => {
      this.zhangjies = res;
      if (!ifMounted) {
        this.filters.title = {};
      }
      this.getList();
    });
  }
  private zhangjieChange(e: any) {
    this.filters.title = e;
    this.$forceUpdate();
    this.getList();
  }
  /**
   * @description 跳转编辑
   */
  private jumpCompose(item: any) {
    if (!this.getIsShowQuanxian("原文校验校验") || item["锁定"]) {
      return;
    }
    this.$store.commit("updateIfCompose", true);
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/compose",
      query: {
        id: item.title_id,
      },
    });
  }
  private goRead(item: any) {
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/read",
      query: {
        id: item.id,
      },
    });
  }
  private openHengji(item: any) {
    // if (!this.getIsShowQuanxian("原文校验查看校验痕迹")) {
    //   return;
    // }
    this.hengjiId = item.book_id;
    this.ifShowHengji = true;
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        页面类型: "原文切分",
        // categ_id: this.filters["分类"] ? this.filters["分类"].id : "",
        check_user_id: this.filters["校验员"] ? this.filters["校验员"].id : "",
        search: this.filters.search,
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        校验状态: this.filters.status,
      },
    };
    localStorage.setItem("ywqfFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetYuanwenList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getChecker() {
    const params: any = {
      params: {
        kind: "原文校验",
      },
    };
    Getchecker(this, params).then((res: any) => {
      this.jiaoyanyuan = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.getFenlei();
    this.filters.sort_field = "更新时间";
    this.filters.type = "";
    this.filters.status = "";
    this.filters.book = {};
    this.filters.title = {};
    this.filters["校验员"] = {};

    const d = localStorage.getItem("ywqfFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
      if (this.filters.book && this.filters.book._id) {
        this.selectBook(this.filters.book, true);
      }
    }
    this.getList();
    this.getChecker();
    this.remoteShumingMethod("");
    this.getChaodai();
    this.getYijia();
  }
}
